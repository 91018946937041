import React, { useEffect } from 'react'
import { useSocket } from '../../contexts/SocketProvider'
import { useLiveVideo } from '../../hooks/useLiveVideo'

import ReactPlayer from 'react-player'
import classes from './VideoPlayerEmbeded.module.css'
import { uploadPath, streamPath } from '../../config'

import ViewersCount from '../ViewersCount_public/ViewersCount'

export default function VideoPlayerEmbeded() {
  const { socket } = useSocket()
  const { liveVideo } = useLiveVideo()

  useEffect(() => {
    socket.emit('connectedUsers')
  }, [socket])

  const vplayer = document.getElementById('videoPlayerEmbeded')
  const liveLabel = document.getElementById('liveLabelEmbeded')
  const isLiveStream = liveVideo().url.endsWith('.m3u8')

  if (vplayer) {
    vplayer.onmouseover = () => {
      if (isLiveStream) {
        if (liveLabel) {
          liveLabel.style.display = 'flex'
        }
      }
      vplayer.onmouseout = () => {
        if (liveLabel) {
          liveLabel.style.display = 'none'
        }
      }
    }
  }

  let videoUrlSrc = ''
  if (liveVideo().url.startsWith('http')) {
    // for local development
    videoUrlSrc = liveVideo().url
  } else if (liveVideo().url.endsWith('.m3u8')) {
    videoUrlSrc = streamPath + liveVideo().url
  } else {
    videoUrlSrc = uploadPath + liveVideo().url
  }

  return (
    <div className={classes.videoPage}>
      <div id='videoPlayerEmbeded' className={classes.videoContainer}>
        <span id='liveLabelEmbeded' className={classes.liveLabel}>
          Live <ViewersCount />
        </span>
        <ReactPlayer
          className={classes.videoPlayer}
          controls
          playing
          muted
          loop
          url={videoUrlSrc}
        />
      </div>
    </div>
  )
}
