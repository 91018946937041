import React, { useContext} from 'react'
import { SelectedVideoContext } from '../../../../../contexts/SelectedVideoCreateContext'
import PublishVideoButton from '../PublishVideoButton/PublishVideoButton'
import classes from './VideoPlayerComp.module.css'
import Player from './Player'
import { CurrentUserContext } from '../../../../../contexts/CurrentUserCreateContext'



export default function VideoPlayerComp() {
  const { selectedVideo } = useContext(SelectedVideoContext)
  const currentUser = useContext(CurrentUserContext)
  const selectedVideoName = Object.keys(selectedVideo)[0]
console.log(currentUser.userStatus)
  return (
    <div style={{ paddingTop: '66px' }}>
      {currentUser.userStatus==='user'?<Player selectedVideo={ {'live stream': 'test.m3u8'} }/>:<Player selectedVideo={ selectedVideo }/>}
      <div className={classes.videoFooter}>
        <div className={classes.videoTitle}>{selectedVideoName}</div>
        <PublishVideoButton />
      </div>
    </div>
  )
}
