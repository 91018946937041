import React,{useContext} from 'react'
import VideoList from './VideoList/VideoList'
import { Row, Col } from 'react-bootstrap'
import UploadVideo from './UploadVideo/UploadVideo'
import AddLiveStreamLink from './AddLiveStreamLink/AddLiveStreamLink'
import VideoPlayerComp from './VideoPlayerComp/VideoPlayerComp'
import classes from './ControlRoom.module.css'
import { CurrentUserContext } from '../../../../contexts/CurrentUserCreateContext'

export default function ControlRoom() {
  const currentUser = useContext(CurrentUserContext)
  return (
    <div className={classes.centerContainer}>
      <Row>
{ currentUser.userStatus==='admin'?       <Col sm={5}>
          <h1 className={classes.videoLibaryTitle}>Video library</h1>
          <AddLiveStreamLink />
          <UploadVideo />
          <VideoList />
        </Col>:null}
        <Col sm={7}>
          <VideoPlayerComp />
        </Col>
      </Row>
    </div>
  )
}
